<template>
  <div class="subscription-successful-page">
    <div class="readable_content form_content">
      <h4>You’re already subscribed…</h4>
      <p>
        And it’s great to hear from you again. Since we already have your email address, stay tuned for our next round
        of family history tips and tales!
      </p>
      <h5>Have you been missing our emails?</h5>
      <p>
        To get the latest scoop every time, be sure to add “<a href="mailto:contact@mychinaroots.com"
          >contact@mychinaroots.com</a
        >” to your email contacts or safe-senders list. If our newsletters show up in your spam or promotions folder,
        adding our email address will help us reach your inbox.
      </p>
      <p>
        P.S. If you haven’t already, join the conversation by following us <strong>@mychinaroots</strong> on
        <a href="https://www.facebook.com/MyChinaRoots/" target="_blank">Facebook</a> and
        <a href="https://www.instagram.com/mychinaroots/" target="_blank">Instagram</a>. We’re excited to hear your
        stories!
      </p>
      <mcr-button-router-link :to="{name: 'main-page'}" class="home-button">Return to Home</mcr-button-router-link>
    </div>
  </div>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';

export default {
  components: {McrButtonRouterLink},
};
</script>

<style scoped></style>
