<template>
  <div class="subscription-successful-page">
    <div class="readable_content form_content">
      <h4>Thanks for signing up!</h4>
      <p>
        To get the latest scoop every time, be sure to add “<a href="mailto:contact@mychinaroots.com"
          >contact@mychinaroots.com</a
        >” to your email contacts or safe-senders list. Stay tuned for our next round of family history tips and tales!
      </p>
      <p>
        P.S. If you’re on social media, follow us <strong>@mychinaroots</strong> to join the conversation on
        <a href="https://www.facebook.com/MyChinaRoots/" target="_blank">Facebook</a> and
        <a href="https://www.instagram.com/mychinaroots/" target="_blank">Instagram</a>. We’re excited to hear your
        stories!
      </p>
      <mcr-button-router-link :to="{name: 'main-page'}" class="home-button">Return to Home</mcr-button-router-link>
    </div>
  </div>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';

export default {
  components: {McrButtonRouterLink},
};
</script>

<style lang="scss" scoped></style>
