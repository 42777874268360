<template>
  <div class="subscription-confirm-page">
    <div class="readable_content form_content">
      <h4>Just one more step…</h4>
      <p>
        We’ve sent a confirmation email to your inbox. Please click the link to activate your newsletter subscription.
      </p>
      <p>
        If you don’t see an email from us, try searching your spam or promotions folder for “My China Roots.” Thanks for
        signing up!
      </p>
      <mcr-button-router-link :to="{name: 'main-page'}" class="home-button">Return to Home</mcr-button-router-link>
    </div>
  </div>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';

export default {
  components: {McrButtonRouterLink},
};
</script>

<style lang="scss" scoped></style>
